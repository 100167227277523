import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import breakDownAllNodes from "utils/breakDownAllNodes";
import Wrapper from "containers/Wrapper";
import Document from "components/Document";

export const query = graphql`
  query PrivacyQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          imageFileName
          anchor
          sections {
            anchor
          }
          copyright
          social {
            linkedin
          }
          privacyText
          termsText
          
          titleTemplate
          keywords
          description
          
          urlIframe
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const PrivacyPage = ({ pathContext, data }) => {
  const { allMarkdownRemark: { nodes } } = data;
  const { privacyNode } = breakDownAllNodes(nodes);
  const { frontmatter: { titleTemplate, urlIframe } } = privacyNode;

  return (
    <Wrapper
      pathContext={pathContext}
      nodes={nodes}
      node={privacyNode}
      className='h-100'
    >
      <Document
        title={titleTemplate}
        url={urlIframe}
      />
    </Wrapper>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

PrivacyPage.defaultProps = {
  pathContext: {
    langKey: "de",
    defaultLang: "de",
    langTextMap: {},
  },
};

export default PrivacyPage;
